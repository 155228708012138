<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-11-01 14:56:51
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-21 17:48:18
 * @FilePath: \sipingnongzhuan2\src\components\menu.vue
-->
<template>
  <div>
    <div class="banner_boxa"
      v-if="isPhone == 'false'">
      <img
        src="../assets/imgaes/banner_bg.jpg"
        class="banner_imga">
      <!-- <img src="bannerUrl"
        class="banner_imga"> -->
      <div
        class="bannenr_texta animation"
        :style="{'width':($route.path=='/xi'?'280px':'250px')}">
        <span>{{rouetObj.name1}}</span>
      </div>
    </div>
    <div class="banner_boxa"
      style="height:150px"
      v-else>
      <img
        src="../assets/imgaes/banner_bg.jpg"
        class="banner_imga">
      <!-- <img src="bannerUrl"
        class="banner_imga"> -->
      <div
        style="height:150px;line-height:150px"
        class="bannenr_texta animation">
        <span
          style="font-size:25px">{{rouetObj.name1}}</span>
      </div>
    </div>
    <div style="
    background:#fff;
max-width: 1160px;
    margin: 0 auto !important;
    padding: 0 20px;">
      <div
        style="display:flex;justify-content:space-between;padding:30px 0 10px 0;border-bottom:1px solid #2984fc">
        <div
          style="font-size: 25px;color:#2984fc">
          {{rouetObj.name2}}
        </div>
        <el-breadcrumb
          style="float:right;line-height:35px"
          separator-class="el-icon-arrow-right">
          <el-breadcrumb-item
            :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>
            {{rouetObj.name1}}
          </el-breadcrumb-item>
          <el-breadcrumb-item><span
              style="color:#2984fc">
              {{rouetObj.name2}}
            </span></el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="left_menu"
      :style="{'width':($route.path == '/xi'?'170px':'160px')}">
      <xi-vue
        v-if="$route.path == '/xi'">
      </xi-vue>
      <div v-else>
        <div
          class="left_menu_news">
          {{rouetObj.name1}}
        </div>
        <el-menu
          :default-active="rouetObj.index"
          class="el-menu-vertical-demo">
          <el-menu-item
            v-for="(item,index) in rouetObj.menuArr"
            :key="index"
            @click="gotoPage(item,index)"
            :index="index+''">
            <i
              class="el-icon-help"></i>
            <span
              slot="title">{{item.name}}</span>
          </el-menu-item>
        </el-menu>
      </div>

    </div>
  </div>
</template>
<script>
import XiVue from './xi.vue'
export default {
  components: {
    XiVue
  },
  data() {
    return {
      isPhone: 'false',
      phoneWidth: 0,
      rouetObj: {}
    }
  },
  watch: {
    $route(to, from) {
      console.log('路由变化了')
      console.log('当前页面路由：' + to.path)
      console.log(from)
      if (to.path == '/xi') {
        this.$set(this.rouetObj, 'name1', to.query.name1)
        this.$set(this.rouetObj, 'name2', to.query.name3 || to.query.name2)
        console.log(this.rouetObj)
      }
      if (to.path != from.path) {
        this.getRoute()
      }
      // this.gotoPage()
    }
  },
  mounted() {
    this.phoneWidth = window.screen.width / 2
    console.log(this.phoneWidth)
    if (window.localStorage.getItem('isPhone')) this.isPhone = window.localStorage.getItem('isPhone')
    else this.isPhone = 'false'
    if (this.$route.path == '/xi') {
      // this.rouetObj = {
      //   name1: this.$route.query.name1,
      //   name2: this.$route.query.name3 || this.$route.query.name2
      // }
      this.$set(this.rouetObj, 'name1', this.$route.query.name1)
      this.$set(this.rouetObj, 'name2', this.$route.query.name3 || this.$route.query.name2)
    } else {
      this.getRoute()
    }
  },
  methods: {
    gotoPage(item, index) {
      this.$store.commit('PATH', item.path)
      this.$store.commit('ID', item.id)
      console.log(item)
      if (item) this.rouetObj.name2 = item.name
      console.log(index)
      this.rouetObj.index = index + ''
      this.$router.push(`${item.path}?id=${item.id}`)
      // this.$router.push(`${this.$route.path}?id=${item.id}`)
    },
    getRoute() {
      console.log(this.$store.state.navList)
      // let rou = this.$store.state.navList
      let rou = JSON.parse(localStorage.getItem('navList'))
      for (let i = 0; i < rou.length; i++) {
        if (rou[i].children) {
          for (let n = 0; n < rou[i].children.length; n++) {
            if (rou[i].children[n].path == this.$route.path) {
              this.rouetObj = {
                name1: rou[i].name,
                name2: rou[i].children[n].name,
                index: n + '',
                path: rou[i].children[n].path,
                menuArr: rou[i].children
              }
            }
          }
        }
      }
      console.log(this.rouetObj)
    }
  }
}
</script>
<style scoped>
.left_menu {
  z-index: 999;
  width: 160px;
  position: absolute;
  top: 510px;
  left: calc((100% - 1160px) / 2 - 200px);
  overflow: hidden;
  /* border: 1px solid rgb(217, 217, 217); */
  background: #fff;
  border-right: 1px solid rgb(217, 217, 217);
  border-left: 1px solid rgb(217, 217, 217);
  border-bottom: 1px solid rgb(217, 217, 217);
}
.left_menu .el-menu-item {
  height: 60px;
  line-height: 60px;
  font-size: 16px;
  border-top: 1px solid rgb(217, 217, 217);
  background: #fff;
}
.left_menu .el-menu-item :hover {
  background: #f6fbff !important;
}
.left_menu .left_menu_news {
  width: 100%;
  background: #2984fc;
  font-weight: 500;
  color: #fff;
  height: 80px;
  text-align: center;
  line-height: 80px;
  font-size: 25px;
}
</style>