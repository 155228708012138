/*
 * @Description:: 请补充填写
 * @Version: 请配置版本号
 * @Author: 小白stweet
 * @Date: 2020-06-03 08:12:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-02-26 08:40:39
 * @FilePath: \sipingnongzhuan2\src\router.js
 */
import Vue from 'vue'
// import store from './store'
// import axios from 'axios'
import Router from 'vue-router'
import NProgress from 'nprogress'
import { REQUEST } from './api/http'
import 'nprogress/nprogress.css'
// import Index from './views/home/index.vue'
// import Index2 from './views/home/index2.vue'
NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 800, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.5 // 初始化时的最小百分比
})
Vue.use(Router)
const router = new Router({
  mode: 'history'
})
REQUEST.POST('/project/selectListForClient', {}).then(res => {
  console.log(res)
  menuRoute(res.data.projectList)
  localStorage.setItem('navList', JSON.stringify(res.data.projectList))
  // localStorage.setItem(
  //   'navList',
  //   JSON.stringify([
  //     {
  //       id: '7118956281188061184',
  //       createTime: '2023-11-01',
  //       parentId: '0',
  //       name: '首页',
  //       register: '一级',
  //       path: '/home',
  //       isEnable: 0,
  //       sort: 1
  //     },
  //     {
  //       id: '7118641236881182720',
  //       createTime: '2023-10-31',
  //       parentId: '0',
  //       name: '学校概况',
  //       register: '一级',
  //       path: '/xxgk',
  //       isEnable: 0,
  //       sort: 2,
  //       children: [
  //         {
  //           id: '7119320267985260544',
  //           createTime: '2023-11-02',
  //           parentId: '7118641236881182720',
  //           name: '学校介绍',
  //           register: '二级',
  //           path: '/xxjs',
  //           isEnable: 0,
  //           sort: 1
  //         },
  //         {
  //           id: '7119320370632462336',
  //           createTime: '2023-11-02',
  //           parentId: '7118641236881182720',
  //           name: '历史沿革',
  //           register: '二级',
  //           path: '/lsyg',
  //           isEnable: 0,
  //           sort: 2
  //         },
  //         {
  //           id: '7119320475930464256',
  //           createTime: '2023-11-02',
  //           parentId: '7118641236881182720',
  //           name: '现任领导',
  //           register: '二级',
  //           path: '/xrld',
  //           isEnable: 0,
  //           sort: 3
  //         },
  //         {
  //           id: '7119320585678622720',
  //           createTime: '2023-11-02',
  //           parentId: '7118641236881182720',
  //           name: '学校标志',
  //           register: '二级',
  //           path: '/xxbz',
  //           isEnable: 0,
  //           sort: 4
  //         },
  //         {
  //           id: '7119320676409806848',
  //           createTime: '2023-11-02',
  //           parentId: '7118641236881182720',
  //           name: '学校章程',
  //           register: '二级',
  //           path: '/xxzc',
  //           isEnable: 0,
  //           sort: 5
  //         }
  //       ]
  //     },
  //     {
  //       id: '7118641324835737600',
  //       createTime: '2023-10-31',
  //       parentId: '0',
  //       name: '机构设置',
  //       register: '一级',
  //       path: '/jgsz',
  //       isEnable: 0,
  //       sort: 3,
  //       children: [
  //         {
  //           id: '7119320776733364224',
  //           createTime: '2023-11-02',
  //           parentId: '7118641324835737600',
  //           name: '院系机构',
  //           register: '二级',
  //           path: '/yxjg',
  //           isEnable: 0,
  //           sort: 1
  //         },
  //         {
  //           id: '7119321019369656320',
  //           createTime: '2023-11-02',
  //           parentId: '7118641324835737600',
  //           name: '管理机构',
  //           register: '二级',
  //           path: '/gljg',
  //           isEnable: 0,
  //           sort: 2
  //         },
  //         {
  //           id: '7119321104019099648',
  //           createTime: '2023-11-02',
  //           parentId: '7118641324835737600',
  //           name: '科研机构',
  //           register: '二级',
  //           path: '/kyjg',
  //           isEnable: 0,
  //           sort: 3
  //         }
  //       ]
  //     },
  //     {
  //       id: '7118641660736573440',
  //       createTime: '2023-10-31',
  //       parentId: '0',
  //       name: '党建引领',
  //       register: '一级',
  //       path: '/djyl',
  //       isEnable: 0,
  //       sort: 4,
  //       children: [
  //         {
  //           id: '7119321278971908096',
  //           createTime: '2023-11-02',
  //           parentId: '7118641660736573440',
  //           name: '主题教育',
  //           register: '二级',
  //           path: '/ztjy',
  //           isEnable: 0,
  //           sort: 1
  //         },
  //         {
  //           id: '7119321341903245312',
  //           createTime: '2023-11-02',
  //           parentId: '7118641660736573440',
  //           name: '党团活动',
  //           register: '二级',
  //           path: '/dthd',
  //           isEnable: 0,
  //           sort: 2
  //         },
  //         {
  //           id: '7119321407586045952',
  //           createTime: '2023-11-02',
  //           parentId: '7118641660736573440',
  //           name: '从严治党',
  //           register: '二级',
  //           path: '/cyzd',
  //           isEnable: 0,
  //           sort: 3
  //         },
  //         {
  //           id: '7119321471700176896',
  //           createTime: '2023-11-02',
  //           parentId: '7118641660736573440',
  //           name: '榜样力量',
  //           register: '二级',
  //           path: '/byll',
  //           isEnable: 0,
  //           sort: 4
  //         }
  //       ]
  //     },
  //     {
  //       id: '7118641774880362496',
  //       createTime: '2023-10-31',
  //       parentId: '0',
  //       name: '教务在线',
  //       register: '一级',
  //       path: '/jwzx',
  //       isEnable: 0,
  //       sort: 5,
  //       children: [
  //         {
  //           id: '7118655064276733952',
  //           createTime: '2023-10-31',
  //           parentId: '7118641774880362496',
  //           name: '组织机构',
  //           register: '二级',
  //           path: '/zzjg',
  //           isEnable: 0,
  //           sort: 1
  //         },
  //         {
  //           id: '7119040748321771520',
  //           createTime: '2023-11-01',
  //           parentId: '7118641774880362496',
  //           name: '规章制度',
  //           register: '二级',
  //           path: '/gzzd',
  //           isEnable: 0,
  //           sort: 2
  //         },
  //         {
  //           id: '7119321578558459904',
  //           createTime: '2023-11-02',
  //           parentId: '7118641774880362496',
  //           name: '教学研究',
  //           register: '二级',
  //           path: '/jxyj',
  //           isEnable: 0,
  //           sort: 3
  //         },
  //         {
  //           id: '7119321638172102656',
  //           createTime: '2023-11-02',
  //           parentId: '7118641774880362496',
  //           name: '教师发展',
  //           register: '二级',
  //           path: '/jsfz',
  //           isEnable: 0,
  //           sort: 4
  //         },
  //         {
  //           id: '7119321736956350464',
  //           createTime: '2023-11-02',
  //           parentId: '7118641774880362496',
  //           name: '教材建设',
  //           register: '二级',
  //           path: '/jcjs',
  //           isEnable: 0,
  //           sort: 5
  //         },
  //         {
  //           id: '7119321810004348928',
  //           createTime: '2023-11-02',
  //           parentId: '7118641774880362496',
  //           name: '实践教学',
  //           register: '二级',
  //           path: '/sjjx',
  //           isEnable: 0,
  //           sort: 6
  //         },
  //         {
  //           id: '7119321901570199552',
  //           createTime: '2023-11-02',
  //           parentId: '7118641774880362496',
  //           name: '考务管理',
  //           register: '二级',
  //           path: '/kwgl',
  //           isEnable: 0,
  //           sort: 7
  //         },
  //         {
  //           id: '7119321996168531968',
  //           createTime: '2023-11-02',
  //           parentId: '7118641774880362496',
  //           name: '支部建设',
  //           register: '二级',
  //           path: '/zbjs',
  //           isEnable: 0,
  //           sort: 8
  //         }
  //       ]
  //     },
  //     {
  //       id: '7118641866521710592',
  //       createTime: '2023-10-31',
  //       parentId: '0',
  //       name: '学生工作',
  //       register: '一级',
  //       path: '/xsgz',
  //       isEnable: 0,
  //       sort: 6,
  //       children: [
  //         {
  //           id: '7119322234744737792',
  //           createTime: '2023-11-02',
  //           parentId: '7118641866521710592',
  //           name: '学籍管理',
  //           register: '二级',
  //           path: '/xjgl',
  //           isEnable: 0,
  //           sort: 1
  //         },
  //         {
  //           id: '7119322615293939712',
  //           createTime: '2023-11-02',
  //           parentId: '7118641866521710592',
  //           name: '思想教育',
  //           register: '二级',
  //           path: '/sxjy',
  //           isEnable: 0,
  //           sort: 2
  //         },
  //         {
  //           id: '7119322666095349760',
  //           createTime: '2023-11-02',
  //           parentId: '7118641866521710592',
  //           name: '通知公告',
  //           register: '二级',
  //           path: '/tzgg',
  //           isEnable: 0,
  //           sort: 3
  //         },
  //         {
  //           id: '7119322755723431936',
  //           createTime: '2023-11-02',
  //           parentId: '7118641866521710592',
  //           name: '处长信箱',
  //           register: '二级',
  //           path: '/xs',
  //           isEnable: 0,
  //           sort: 4
  //         }
  //       ]
  //     },
  //     {
  //       id: '7118641933689294848',
  //       createTime: '2023-10-31',
  //       parentId: '0',
  //       name: '招生就业',
  //       register: '一级',
  //       path: '/zsjy',
  //       isEnable: 0,
  //       sort: 7,
  //       children: [
  //         {
  //           id: '7119322921234862080',
  //           createTime: '2023-11-02',
  //           parentId: '7118641933689294848',
  //           name: '招生资讯',
  //           register: '二级',
  //           path: '/zszx',
  //           isEnable: 0,
  //           sort: 1
  //         },
  //         {
  //           id: '7119322976121524224',
  //           createTime: '2023-11-02',
  //           parentId: '7118641933689294848',
  //           name: '专业设置',
  //           register: '二级',
  //           path: '/zysz',
  //           isEnable: 0,
  //           sort: 2
  //         }
  //       ]
  //     },
  //     {
  //       id: '7118642090229108736',
  //       createTime: '2023-10-31',
  //       parentId: '0',
  //       name: '安全保卫',
  //       register: '一级',
  //       path: '/aqbw',
  //       isEnable: 0,
  //       sort: 8,
  //       children: [
  //         {
  //           id: '7119323070262677504',
  //           createTime: '2023-11-02',
  //           parentId: '7118642090229108736',
  //           name: '管理制度',
  //           register: '二级',
  //           path: '/glzd',
  //           isEnable: 0,
  //           sort: 1
  //         },
  //         {
  //           id: '7119323130610323456',
  //           createTime: '2023-11-02',
  //           parentId: '7118642090229108736',
  //           name: '政策法规',
  //           register: '二级',
  //           path: '/zcfg',
  //           isEnable: 0,
  //           sort: 2
  //         },
  //         {
  //           id: '7119323217012985856',
  //           createTime: '2023-11-02',
  //           parentId: '7118642090229108736',
  //           name: '安全教育',
  //           register: '二级',
  //           path: '/aqjy',
  //           isEnable: 0,
  //           sort: 3
  //         },
  //         {
  //           id: '7119323280833515520',
  //           createTime: '2023-11-02',
  //           parentId: '7118642090229108736',
  //           name: '通知公告',
  //           register: '二级',
  //           path: '/tzgg',
  //           isEnable: 0,
  //           sort: 4
  //         }
  //       ]
  //     },
  //     {
  //       id: '7118642156423614464',
  //       createTime: '2023-10-31',
  //       parentId: '0',
  //       name: '公共服务',
  //       register: '一级',
  //       path: '/ggfw',
  //       isEnable: 0,
  //       sort: 9,
  //       children: [
  //         {
  //           id: '7118642371595603968',
  //           createTime: '2023-10-31',
  //           parentId: '7118642156423614464',
  //           name: '校历',
  //           register: '二级',
  //           path: '/xl',
  //           isEnable: 0,
  //           sort: 1
  //         },
  //         {
  //           id: '7121587808858279936',
  //           createTime: '2023-11-08',
  //           parentId: '7118642156423614464',
  //           name: '校长信箱',
  //           register: '二级',
  //           path: '/xzxx',
  //           isEnable: 0,
  //           sort: 2
  //         }
  //       ]
  //     },
  //     {
  //       id: '7121491972828696576',
  //       createTime: '2023-11-08',
  //       parentId: '0',
  //       name: '校园新闻',
  //       register: '一级',
  //       path: '/xyxw',
  //       isEnable: 0,
  //       sort: 10,
  //       children: [
  //         {
  //           id: '7121492951515009024',
  //           createTime: '2023-11-08',
  //           parentId: '7121491972828696576',
  //           name: '校园新闻',
  //           register: '二级',
  //           path: '/xyxw',
  //           isEnable: 0,
  //           sort: 1
  //         }
  //       ]
  //     },
  //     {
  //       id: '7121495189360742400',
  //       createTime: '2023-11-08',
  //       parentId: '0',
  //       name: '通知公告',
  //       register: '一级',
  //       path: '/tzgg1',
  //       isEnable: 0,
  //       sort: 11,
  //       children: [
  //         {
  //           id: '7121499577705828352',
  //           createTime: '2023-11-08',
  //           parentId: '7121495189360742400',
  //           name: '通知公告',
  //           register: '二级',
  //           path: '/tzgg1',
  //           isEnable: 0,
  //           sort: 1
  //         }
  //       ]
  //     },
  //     {
  //       id: '7121499144375504896',
  //       createTime: '2023-11-08',
  //       parentId: '0',
  //       name: '党团活动',
  //       register: '一级',
  //       path: '/dthd1',
  //       isEnable: 0,
  //       sort: 12,
  //       children: [
  //         {
  //           id: '7121499646148481024',
  //           createTime: '2023-11-08',
  //           parentId: '7121499144375504896',
  //           name: '党团活动',
  //           register: '二级',
  //           path: '/dthd1',
  //           isEnable: 0,
  //           sort: 1
  //         }
  //       ]
  //     },
  //     {
  //       id: '7121499496176947200',
  //       createTime: '2023-11-08',
  //       parentId: '0',
  //       name: '教学动态',
  //       register: '一级',
  //       path: '/jxdt1',
  //       isEnable: 0,
  //       sort: 13,
  //       children: [
  //         {
  //           id: '7121499708261928960',
  //           createTime: '2023-11-08',
  //           parentId: '7121499496176947200',
  //           name: '教学动态',
  //           register: '二级',
  //           path: '/jxdt1',
  //           isEnable: 0,
  //           sort: 1
  //         }
  //       ]
  //     }
  //   ])
  // )
  // menuRoute([
  //   {
  //     id: '7118956281188061184',
  //     createTime: '2023-11-01',
  //     parentId: '0',
  //     name: '首页',
  //     register: '一级',
  //     path: '/home',
  //     isEnable: 0,
  //     sort: 1
  //   },
  //   {
  //     id: '7118641236881182720',
  //     createTime: '2023-10-31',
  //     parentId: '0',
  //     name: '学校概况',
  //     register: '一级',
  //     path: '/xxgk',
  //     isEnable: 0,
  //     sort: 2,
  //     children: [
  //       {
  //         id: '7119320267985260544',
  //         createTime: '2023-11-02',
  //         parentId: '7118641236881182720',
  //         name: '学校介绍',
  //         register: '二级',
  //         path: '/xxjs',
  //         isEnable: 0,
  //         sort: 1
  //       },
  //       {
  //         id: '7119320370632462336',
  //         createTime: '2023-11-02',
  //         parentId: '7118641236881182720',
  //         name: '历史沿革',
  //         register: '二级',
  //         path: '/lsyg',
  //         isEnable: 0,
  //         sort: 2
  //       },
  //       {
  //         id: '7119320475930464256',
  //         createTime: '2023-11-02',
  //         parentId: '7118641236881182720',
  //         name: '现任领导',
  //         register: '二级',
  //         path: '/xrld',
  //         isEnable: 0,
  //         sort: 3
  //       },
  //       {
  //         id: '7119320585678622720',
  //         createTime: '2023-11-02',
  //         parentId: '7118641236881182720',
  //         name: '学校标志',
  //         register: '二级',
  //         path: '/xxbz',
  //         isEnable: 0,
  //         sort: 4
  //       },
  //       {
  //         id: '7119320676409806848',
  //         createTime: '2023-11-02',
  //         parentId: '7118641236881182720',
  //         name: '学校章程',
  //         register: '二级',
  //         path: '/xxzc',
  //         isEnable: 0,
  //         sort: 5
  //       }
  //     ]
  //   },
  //   {
  //     id: '7118641324835737600',
  //     createTime: '2023-10-31',
  //     parentId: '0',
  //     name: '机构设置',
  //     register: '一级',
  //     path: '/jgsz',
  //     isEnable: 0,
  //     sort: 3,
  //     children: [
  //       {
  //         id: '7119320776733364224',
  //         createTime: '2023-11-02',
  //         parentId: '7118641324835737600',
  //         name: '院系机构',
  //         register: '二级',
  //         path: '/yxjg',
  //         isEnable: 0,
  //         sort: 1
  //       },
  //       {
  //         id: '7119321019369656320',
  //         createTime: '2023-11-02',
  //         parentId: '7118641324835737600',
  //         name: '管理机构',
  //         register: '二级',
  //         path: '/gljg',
  //         isEnable: 0,
  //         sort: 2
  //       },
  //       {
  //         id: '7119321104019099648',
  //         createTime: '2023-11-02',
  //         parentId: '7118641324835737600',
  //         name: '科研机构',
  //         register: '二级',
  //         path: '/kyjg',
  //         isEnable: 0,
  //         sort: 3
  //       }
  //     ]
  //   },
  //   {
  //     id: '7118641660736573440',
  //     createTime: '2023-10-31',
  //     parentId: '0',
  //     name: '党建引领',
  //     register: '一级',
  //     path: '/djyl',
  //     isEnable: 0,
  //     sort: 4,
  //     children: [
  //       {
  //         id: '7119321278971908096',
  //         createTime: '2023-11-02',
  //         parentId: '7118641660736573440',
  //         name: '主题教育',
  //         register: '二级',
  //         path: '/ztjy',
  //         isEnable: 0,
  //         sort: 1
  //       },
  //       {
  //         id: '7119321341903245312',
  //         createTime: '2023-11-02',
  //         parentId: '7118641660736573440',
  //         name: '党团活动',
  //         register: '二级',
  //         path: '/dthd',
  //         isEnable: 0,
  //         sort: 2
  //       },
  //       {
  //         id: '7119321407586045952',
  //         createTime: '2023-11-02',
  //         parentId: '7118641660736573440',
  //         name: '从严治党',
  //         register: '二级',
  //         path: '/cyzd',
  //         isEnable: 0,
  //         sort: 3
  //       },
  //       {
  //         id: '7119321471700176896',
  //         createTime: '2023-11-02',
  //         parentId: '7118641660736573440',
  //         name: '榜样力量',
  //         register: '二级',
  //         path: '/byll',
  //         isEnable: 0,
  //         sort: 4
  //       }
  //     ]
  //   },
  //   {
  //     id: '7118641774880362496',
  //     createTime: '2023-10-31',
  //     parentId: '0',
  //     name: '教务在线',
  //     register: '一级',
  //     path: '/jwzx',
  //     isEnable: 0,
  //     sort: 5,
  //     children: [
  //       {
  //         id: '7118655064276733952',
  //         createTime: '2023-10-31',
  //         parentId: '7118641774880362496',
  //         name: '组织机构',
  //         register: '二级',
  //         path: '/zzjg',
  //         isEnable: 0,
  //         sort: 1
  //       },
  //       {
  //         id: '7119040748321771520',
  //         createTime: '2023-11-01',
  //         parentId: '7118641774880362496',
  //         name: '规章制度',
  //         register: '二级',
  //         path: '/gzzd',
  //         isEnable: 0,
  //         sort: 2
  //       },
  //       {
  //         id: '7119321578558459904',
  //         createTime: '2023-11-02',
  //         parentId: '7118641774880362496',
  //         name: '教学研究',
  //         register: '二级',
  //         path: '/jxyj',
  //         isEnable: 0,
  //         sort: 3
  //       },
  //       {
  //         id: '7119321638172102656',
  //         createTime: '2023-11-02',
  //         parentId: '7118641774880362496',
  //         name: '教师发展',
  //         register: '二级',
  //         path: '/jsfz',
  //         isEnable: 0,
  //         sort: 4
  //       },
  //       {
  //         id: '7119321736956350464',
  //         createTime: '2023-11-02',
  //         parentId: '7118641774880362496',
  //         name: '教材建设',
  //         register: '二级',
  //         path: '/jcjs',
  //         isEnable: 0,
  //         sort: 5
  //       },
  //       {
  //         id: '7119321810004348928',
  //         createTime: '2023-11-02',
  //         parentId: '7118641774880362496',
  //         name: '实践教学',
  //         register: '二级',
  //         path: '/sjjx',
  //         isEnable: 0,
  //         sort: 6
  //       },
  //       {
  //         id: '7119321901570199552',
  //         createTime: '2023-11-02',
  //         parentId: '7118641774880362496',
  //         name: '考务管理',
  //         register: '二级',
  //         path: '/kwgl',
  //         isEnable: 0,
  //         sort: 7
  //       },
  //       {
  //         id: '7119321996168531968',
  //         createTime: '2023-11-02',
  //         parentId: '7118641774880362496',
  //         name: '支部建设',
  //         register: '二级',
  //         path: '/zbjs',
  //         isEnable: 0,
  //         sort: 8
  //       }
  //     ]
  //   },
  //   {
  //     id: '7118641866521710592',
  //     createTime: '2023-10-31',
  //     parentId: '0',
  //     name: '学生工作',
  //     register: '一级',
  //     path: '/xsgz',
  //     isEnable: 0,
  //     sort: 6,
  //     children: [
  //       {
  //         id: '7119322234744737792',
  //         createTime: '2023-11-02',
  //         parentId: '7118641866521710592',
  //         name: '学籍管理',
  //         register: '二级',
  //         path: '/xjgl',
  //         isEnable: 0,
  //         sort: 1
  //       },
  //       {
  //         id: '7119322615293939712',
  //         createTime: '2023-11-02',
  //         parentId: '7118641866521710592',
  //         name: '思想教育',
  //         register: '二级',
  //         path: '/sxjy',
  //         isEnable: 0,
  //         sort: 2
  //       },
  //       {
  //         id: '7119322666095349760',
  //         createTime: '2023-11-02',
  //         parentId: '7118641866521710592',
  //         name: '通知公告',
  //         register: '二级',
  //         path: '/tzgg',
  //         isEnable: 0,
  //         sort: 3
  //       },
  //       {
  //         id: '7119322755723431936',
  //         createTime: '2023-11-02',
  //         parentId: '7118641866521710592',
  //         name: '处长信箱',
  //         register: '二级',
  //         path: '/xs',
  //         isEnable: 0,
  //         sort: 4
  //       }
  //     ]
  //   },
  //   {
  //     id: '7118641933689294848',
  //     createTime: '2023-10-31',
  //     parentId: '0',
  //     name: '招生就业',
  //     register: '一级',
  //     path: '/zsjy',
  //     isEnable: 0,
  //     sort: 7,
  //     children: [
  //       {
  //         id: '7119322921234862080',
  //         createTime: '2023-11-02',
  //         parentId: '7118641933689294848',
  //         name: '招生资讯',
  //         register: '二级',
  //         path: '/zszx',
  //         isEnable: 0,
  //         sort: 1
  //       },
  //       {
  //         id: '7119322976121524224',
  //         createTime: '2023-11-02',
  //         parentId: '7118641933689294848',
  //         name: '专业设置',
  //         register: '二级',
  //         path: '/zysz',
  //         isEnable: 0,
  //         sort: 2
  //       }
  //     ]
  //   },
  //   {
  //     id: '7118642090229108736',
  //     createTime: '2023-10-31',
  //     parentId: '0',
  //     name: '安全保卫',
  //     register: '一级',
  //     path: '/aqbw',
  //     isEnable: 0,
  //     sort: 8,
  //     children: [
  //       {
  //         id: '7119323070262677504',
  //         createTime: '2023-11-02',
  //         parentId: '7118642090229108736',
  //         name: '管理制度',
  //         register: '二级',
  //         path: '/glzd',
  //         isEnable: 0,
  //         sort: 1
  //       },
  //       {
  //         id: '7119323130610323456',
  //         createTime: '2023-11-02',
  //         parentId: '7118642090229108736',
  //         name: '政策法规',
  //         register: '二级',
  //         path: '/zcfg',
  //         isEnable: 0,
  //         sort: 2
  //       },
  //       {
  //         id: '7119323217012985856',
  //         createTime: '2023-11-02',
  //         parentId: '7118642090229108736',
  //         name: '安全教育',
  //         register: '二级',
  //         path: '/aqjy',
  //         isEnable: 0,
  //         sort: 3
  //       },
  //       {
  //         id: '7119323280833515520',
  //         createTime: '2023-11-02',
  //         parentId: '7118642090229108736',
  //         name: '通知公告',
  //         register: '二级',
  //         path: '/tzgg',
  //         isEnable: 0,
  //         sort: 4
  //       }
  //     ]
  //   },
  //   {
  //     id: '7118642156423614464',
  //     createTime: '2023-10-31',
  //     parentId: '0',
  //     name: '公共服务',
  //     register: '一级',
  //     path: '/ggfw',
  //     isEnable: 0,
  //     sort: 9,
  //     children: [
  //       {
  //         id: '7118642371595603968',
  //         createTime: '2023-10-31',
  //         parentId: '7118642156423614464',
  //         name: '校历',
  //         register: '二级',
  //         path: '/xl',
  //         isEnable: 0,
  //         sort: 1
  //       },
  //       {
  //         id: '7121587808858279936',
  //         createTime: '2023-11-08',
  //         parentId: '7118642156423614464',
  //         name: '校长信箱',
  //         register: '二级',
  //         path: '/xzxx',
  //         isEnable: 0,
  //         sort: 2
  //       }
  //     ]
  //   },
  //   {
  //     id: '7121491972828696576',
  //     createTime: '2023-11-08',
  //     parentId: '0',
  //     name: '校园新闻',
  //     register: '一级',
  //     path: '/xyxw',
  //     isEnable: 0,
  //     sort: 10,
  //     children: [
  //       {
  //         id: '7121492951515009024',
  //         createTime: '2023-11-08',
  //         parentId: '7121491972828696576',
  //         name: '校园新闻',
  //         register: '二级',
  //         path: '/xyxw',
  //         isEnable: 0,
  //         sort: 1
  //       }
  //     ]
  //   },
  //   {
  //     id: '7121495189360742400',
  //     createTime: '2023-11-08',
  //     parentId: '0',
  //     name: '通知公告',
  //     register: '一级',
  //     path: '/tzgg1',
  //     isEnable: 0,
  //     sort: 11,
  //     children: [
  //       {
  //         id: '7121499577705828352',
  //         createTime: '2023-11-08',
  //         parentId: '7121495189360742400',
  //         name: '通知公告',
  //         register: '二级',
  //         path: '/tzgg1',
  //         isEnable: 0,
  //         sort: 1
  //       }
  //     ]
  //   },
  //   {
  //     id: '7121499144375504896',
  //     createTime: '2023-11-08',
  //     parentId: '0',
  //     name: '党团活动',
  //     register: '一级',
  //     path: '/dthd1',
  //     isEnable: 0,
  //     sort: 12,
  //     children: [
  //       {
  //         id: '7121499646148481024',
  //         createTime: '2023-11-08',
  //         parentId: '7121499144375504896',
  //         name: '党团活动',
  //         register: '二级',
  //         path: '/dthd1',
  //         isEnable: 0,
  //         sort: 1
  //       }
  //     ]
  //   },
  //   {
  //     id: '7121499496176947200',
  //     createTime: '2023-11-08',
  //     parentId: '0',
  //     name: '教学动态',
  //     register: '一级',
  //     path: '/jxdt1',
  //     isEnable: 0,
  //     sort: 13,
  //     children: [
  //       {
  //         id: '7121499708261928960',
  //         createTime: '2023-11-08',
  //         parentId: '7121499496176947200',
  //         name: '教学动态',
  //         register: '二级',
  //         path: '/jxdt1',
  //         isEnable: 0,
  //         sort: 1
  //       }
  //     ]
  //   }
  // ])
})
function menuRoute(asyncRoutes) {
  const dynamicRoute = {
    path: '/',
    component: () => import('./views/main'),
    children: []
  }
  let route = {}
  asyncRoutes.forEach(e => {
    route = {
      name: e.name,
      path: e.path,
      component: () => import('./views' + e.path),
      children: []
    }
    if (e.children) {
      e.children.forEach(c => {
        route.children.push({
          name: c.name,
          path: c.path,
          component: () => import('./views' + e.path + '/index.vue')
        })
      })
    } else {
      route = {
        name: e.name,
        path: e.path,
        component: () => import('./views' + e.path + '/index'),
        children: []
      }
    }
    dynamicRoute.children.push(route)
  })
  // dynamicRoute.children.push(
  //   // 映射到home页面
  //   {
  //     path: '/',
  //     name: '首页',
  //     component: () => import('./views/home/index')
  //   }
  // )
  console.log(dynamicRoute)
  router.addRoute(dynamicRoute)
  router.options.routes = dynamicRoute
}
// asyncRoutes.push({ path: '*', redirect: '/' })
// console.log(asyncRoutes)
// router实例
router.beforeEach((to, from, next) => {
  console.log(to, from, next)
  if (to.path == '/') {
    next('/home')
  }
  NProgress.start()
  next()
})
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

export default router
