/*
 * @Description:: 请补充填写
 * @Version: 请配置版本号
 * @Author: 小白stweet
 * @Date: 2019-11-27 15:31:33
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-02-26 08:36:57
 * @FilePath: \sipingnongzhuan2\src\main.js
 */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import animate from 'animate.css'
import elementUI from 'element-ui'
import { REQUEST } from '@/api/http'
import Menu from './components/menu.vue'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'element-ui/lib/theme-chalk/index.css'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'
import 'froala-editor/js/froala_editor.pkgd.min.js'
import 'froala-editor/js/languages/zh_cn.js'
import 'froala-editor/js/plugins.pkgd.min.js'
import VueFroala from 'vue-froala-wysiwyg'
import store from './store'
Vue.config.productionTip = false
import BaiduMap from 'vue-baidu-map'
Vue.use(animate)
Vue.use(elementUI)
Vue.use(VueFroala)
Vue.use(BaiduMap, {
  ak: 'IwatH8ut7ZyMQGy5PNWQ2v7tRYovMadB'
})
Vue.component('menu-view', Menu)
import axios from 'axios'
import baseURL from './api/base'
import qs from 'qs'
Vue.prototype.$post = REQUEST.POST
Vue.prototype.$posts = REQUEST.POSTS
Vue.prototype.$get = REQUEST.GET
console.log(baseURL)
axios.defaults.baseURL = baseURL
// axios.defaults.baseURL = 'http://api.fjmxjt.cn/'

axios.interceptors.request.use(config => {
  if (config.method == 'post') {
    // config.headers["Content-Type"] = "application/json;charset=UTF-8";
    config.data = qs.stringify(config.data)
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  }
  return config
})
Vue.prototype.$axios = axios

//加载动画
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 500, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
})
//当路由进入前
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  NProgress.start()
  next()
})
//当路由进入后：关闭进度条
router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})
let Vm
Vm = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
export const vm = Vm
