/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-11-01 10:01:12
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-23 08:44:29
 * @FilePath: \sipingnongzhuan2\src\api\base.js
 */
// export const baseURL = 'http://192.168.70.141:8088'
let baseURL = '' //这里是一个默认的url，可以没有

switch (process.env.NODE_ENV) {
  case 'development':
    // baseURL = 'http://192.168.70.141:8088' //开发环境url
    baseURL = 'https://www.spnz.com.cn/prod_api2' //开发环境url
    break

  case 'production':
    baseURL = 'https://www.spnz.com.cn/prod_api2' //生产环境url
    break
}
export default baseURL
