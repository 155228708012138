/*
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: 小白
 * @Date: 2022-03-02 13:14:18
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-08 18:05:25
 * @FilePath: \sipingnongzhuan2\src\api\http.js
 */
import request from './request'
import baseURL from './base'
function params(obj) {
  let result = ''
  let item
  for (item in obj) {
    if (obj[item] && String(obj[item])) {
      result += `&${item}=${obj[item]}`
    }
  }
  if (result) {
    result = '?' + result.slice(1)
  }
  return result
}
export const REQUEST = {
  POST: function (businessType, datas) {
    return request({
      url: baseURL + businessType + params(datas),
      method: 'post',
      data: {}
    })
  },
  POSTS: function (businessType, datas) {
    return request({
      url: baseURL + businessType,
      method: 'postq',
      data: datas
    })
  },
  POSTES: function (businessType, id, datas) {
    return request({
      url: baseURL + businessType,
      method: 'post',
      data: datas
    })
  },
  GET: function (businessType, params) {
    return request({
      url: baseURL + businessType,
      method: 'get',
      params: params
    })
  },
  GETS: function (businessType, params) {
    return request({
      url: baseURL + businessType + '/' + params
    })
  },
  PUT: function (businessType, params) {
    return request({
      url: baseURL + businessType,
      method: 'put',
      data: params
    })
  },
  DELETE: function (businessType, params) {
    return request({
      url: baseURL + businessType + '/' + params,
      method: 'delete'
    })
  },
  POSTFILE: function (businessType, params) {
    return request({
      url: baseURL + businessType,
      method: 'post',
      data: params,
      headers: { 'Content-Type': 'multipart/form-data' } // 设置请求头为文件流
    })
  }
}
export default REQUEST
