<!--
 * @Description:: 请补充填写
 * @Version: 请配置版本号
 * @Author: xiaobai
 * @Date: 2021-06-10 14:21:24
 * @LastEditors: smallWhite
 * @LastEditTime: 2024-02-26 08:36:39
 * @FilePath: \sipingnongzhuan2\src\App.vue
-->
<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  mounted() {
    //获取浏览器navigator对象的userAgent属性（浏览器用于HTTP请求的用户代理头的值）
    var info = navigator.userAgent
    //通过正则表达式的test方法判断是否包含“Mobile”字符串
    var isPhone = /mobile/i.test(info)
    //如果包含“Mobile”（是手机设备）则返回true
    isPhone
    window.localStorage.setItem('isPhone', isPhone)
  }
}
</script>

<style>
@import url('./assets/css/style.css');
@import url('./assets/css/edit.css');
.ql-align-center {
  display: flex;
  justify-content: center;
}
.el-menu {
  background: #f6fbff !important;
  border-right: 0 !important;
}
</style>
