/*
 * @Description:: 请补充填写
 * @Version: 请配置版本号
 * @Author: 小白
 * @Date: 2020-06-10 11:39:15
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-11-02 18:07:32
 * @FilePath: \sipingnongzhuan2\src\store.js
 */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    openNav: [],
    navList: []
  },
  mutations: {
    setOpenNav(state, nav) {
      state.openNav = nav
    },
    setNavList(state, list) {
      console.log(list)
      state.navList = list
    },
    PATH: (state, path) => {
      console.log(path)
      state.path = path
    },
    ID: (state, id) => {
      console.log(id)
      state.id = id
    }
  },
  actions: {
    //
  },
  getters: {
    navList: state => state.navList
  },
  plugins: [
    createPersistedState({
      // storage: window.sessionStorage
      storage: window.localStorage
    })
  ]
})
