import axios from 'axios'
import { Notification, Message } from 'element-ui'
import cache from './cache'
// import store from '@/store/index.js'
import NProgress from 'nprogress'
// loading开始 方法
function startLoading() {
  // element-ui loading 服务调用方式
  NProgress.start() //
}
// loading结束 方法
function endLoading() {
  NProgress.done()
}

// 定义一个变量，有请求变量加一，收到响应变量减一
let loadingCount = 0
/**
 * 调用一次startLoadingAddCount() 方法 开启Loading 并 loadingCount + 1
 * 调用一次endLoadingSubCount() 方法 loadingCount - 1 直到为0 关闭loading
 */
export function startLoadingAddCount() {
  if (loadingCount === 0) {
    startLoading()
  }
  loadingCount++
}

export function endLoadingSubCount() {
  loadingCount--
  if (loadingCount === 0) {
    endLoading()
  }
}
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // 超时
  timeout: 100000
})

// request拦截器
service.interceptors.request.use(
  config => {
    startLoadingAddCount()

    // 是否需要设置 token
    const isToken = (config.headers || {}).isToken === false
    // 是否需要防止数据重复提交
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
    // eslint-disable-next-line no-undef
    const token = localStorage.getItem('token')
    if (token && !isToken) {
      config.headers['token'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    //
    if (!isRepeatSubmit && config.method === 'post') {
      const datas = config.data
      const data = Object.keys(datas).map(item => {
        if (datas[item] === '' || datas[item] == null) delete datas[item]
      })
      const requestObj = {
        url: config.url,
        data: data,
        time: new Date().getTime()
      }
      const sessionObj = cache.session.getJSON('sessionObj')
      if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
    return config
  },
  error => {
    endLoadingSubCount()
    Promise.reject(error)
  }
)
export function downloadjson(url, params, filename) {
  console.log(params, '0000')
  return axios({
    method: 'post',
    url: url,
    data: params,
    headers: {
      'Content-Type': 'application/json',
      token: window.localStorage.getItem('token')
    },
    responseType: 'blob'
  }).then(async data => {
    console.log(data.data)
    const link = document.createElement('a')
    try {
      Message({
        message: '正在下载',
        type: 'success'
      })
      const blob = data.data
      link.style.display = 'none'
      // 兼容不同浏览器的URL对象
      const url = window.URL || window.webkitURL || window.moxURL
      link.href = window.URL.createObjectURL(
        new Blob([blob])
        // new Blob([blob], {
        //   //这里的type要和后端一致
        //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        // })
      )
      link.download = filename
      link.click()
      window.URL.revokeObjectURL(url)
    } catch (e) {
      console.log('下载的文件出错', e)
    }
  })
}
export function downloadForm(url, params, filename) {
  var dataInfo = new FormData()
  dataInfo.append('list', params.list)
  return axios({
    method: 'post',
    url: url,
    data: dataInfo,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
      token: window.localStorage.getItem('token')
    },
    responseType: 'blob'
  }).then(async data => {
    const link = document.createElement('a')
    try {
      Message({
        message: '正在下载',
        type: 'success'
      })
      const blob = data
      link.style.display = 'none'
      // 兼容不同浏览器的URL对象
      const url = window.URL || window.webkitURL || window.moxURL
      // link.href = url.createObjectURL(blob);
      link.href = window.URL.createObjectURL(new Blob([blob]))
      link.download = filename
      link.click()
      window.URL.revokeObjectURL(url)
    } catch (e) {
      console.log('下载的文件出错', e)
    }
  })
}

// 响应拦截器
service.interceptors.response.use(
  res => {
    endLoadingSubCount()
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200
    // 获取错误信息
    const msg = res.data.message || res.data.msg
    if (code !== 200 && code !== 20000 && code !== 900) {
      Notification.error({
        title: msg
      })
      // setTimeout(() => {
      //   router.push('/white')
      // }, 1000)
      return res.data
    } else if (code === 900) {
      Notification.error({
        title: msg
      })
      window.localStorage.clear()
      setTimeout(() => {
        window.location.href = `/login`
      }, 1000)
      return Promise.reject('error')
    } else {
      return res.data
    }
  },
  error => {
    endLoadingSubCount()
    let { message } = error
    if (message === 'Network Error') {
      message = '网络连接错误'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)
export default service
