<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-11-20 16:46:44
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-12-04 15:01:13
 * @FilePath: \sipingnongzhuan2\src\components\xi.vue
-->
<template>
  <div class="bg_menu">
    <div class="menu_title">
      <i style="font-size:20px"
        class="el-icon-mouse"></i>
      系部导航
    </div>
    <el-menu
      default-active="1"
      class="el-menu-vertical-demo"
      :collapse="true">
      <el-submenu
        v-for="(item,index) in xiList"
        :key="index"
        :index="index+''">
        <template
          slot="title">
          <img
            style="width:18px;margin-right:3px"
            :src="item.img"
            alt="">
          <!-- <i style="text-align:right;display:inline"
                class="el-icon-arrow-right"></i> -->
          <span
            style="display: inline-block;width:90px;height:56px;font-size:16px;font-weight:500"
            slot="title">{{item.name}}</span>
          <i style="text-align:right;display:inline"
            class="el-icon-arrow-right"></i>
        </template>
        <div
          v-for="(item2,index2) in item.son"
          :key="index2">
          <el-menu-item-group
            v-if="item2.label != '专业设置'"
            style="margin-top:-7px">
            <el-menu-item
              @click="goXi(item,item2)"
              :index="index +'-' + index2">{{item2.label}}</el-menu-item>
          </el-menu-item-group>
          <el-submenu
            v-if="item2.child && item2.child.length >0"
            :index="index +'-' + index2">
            <span
              slot="title">专业设置</span>
            <el-menu-item
              v-for="(item3,index3) in item2.child"
              :key="index3"
              @click="goXi(item,item2,item3)"
              :index="index +'-' + index2 +'-' +index3">{{item3.xi}}</el-menu-item>
          </el-submenu>
        </div>
      </el-submenu>
    </el-menu>
  </div>
</template>
<script>
// import axios from 'axios'
import xiaotubiao1 from '../assets/imgaes/xiaotubiao1.png'
import xiaotubiao2 from '../assets/imgaes/xiaotubiao2.png'
import xiaotubiao3 from '../assets/imgaes/xiaotubiao3.png'
import xiaotubiao4 from '../assets/imgaes/xiaotubiao4.png'
import xiaotubiao5 from '../assets/imgaes/xiaotubiao5.png'
import xiaotubiao6 from '../assets/imgaes/xiaotubiao6.png'
export default {
  data() {
    return {
      xiList: [
        {
          name: '农业工程系',
          img: xiaotubiao1,
          son: [
            {
              label: '专业设置',
              child: [
                { xi: '作物生产与经营管理', value: '1111' },
                { xi: '林业技术', value: '1112' },
                { xi: '园林技术', value: '1113' },
                { xi: '水利工程', value: '1114' }
              ]
            },
            { label: '系部简介', value: '1115' },
            { label: '机构设置', value: '1116' },
            { label: '师资力量', value: '1117' },
            { label: '人才培养方案', value: '1118' },
            { label: '教学管理', value: '1119' },
            { label: '教学教研', value: '1120' }
          ]
        },
        {
          name: '牧医系',
          img: xiaotubiao2,
          son: [
            {
              label: '专业设置',
              child: [
                { xi: '畜牧兽医', value: '2221' },
                { xi: '食品智能加工技术', value: '2222' }
              ]
            },
            { label: '系部简介', value: '2223' },
            { label: '机构设置', value: '2224' },
            { label: '师资力量', value: '2225' },
            { label: '人才培养方案', value: '2226' },
            { label: '教学管理', value: '2227' },
            { label: '教学教研', value: '2228' }
          ]
        },
        {
          name: '财经系',
          img: xiaotubiao3,
          son: [
            {
              label: '专业设置',
              child: [
                { xi: '大数据与会计', value: '3331' },
                { xi: '国际经济与贸易', value: '3332' },
                { xi: '工商企业管理', value: '3333' },
                { xi: '现代物流管理', value: '3334' },
                { xi: '国土资源调查与管理', value: '3335' }
              ]
            },
            { label: '系部简介', value: '3336' },
            { label: '机构设置', value: '3337' },
            { label: '师资力量', value: '3338' },
            { label: '人才培养方案', value: '3339' },
            { label: '教学管理', value: '3340' },
            { label: '教学教研', value: '3341' }
          ]
        },
        {
          name: '人文系',
          img: xiaotubiao4,
          son: [
            {
              label: '专业设置',
              child: [
                { xi: '法律事务', value: '4441' },
                { xi: '社会工作', value: '4442' },
                { xi: '戏曲表演', value: '4443' },
                { xi: '新闻采编与制作', value: '4444' }
              ]
            },
            { label: '系部简介', value: '4445' },
            { label: '机构设置', value: '4446' },
            { label: '师资力量', value: '4447' },
            { label: '人才培养方案', value: '4448' },
            { label: '教学管理', value: '4449' },
            { label: '教学教研', value: '4450' }
          ]
        },
        {
          name: '计算机系',
          img: xiaotubiao5,
          son: [
            {
              label: '专业设置',
              child: [
                { xi: '计算机应用技术', value: '5551' },
                { xi: '机电一体化技术', value: '5552' },
                { xi: '机械制造及自动化', value: '5553' },
                { xi: '供用电技术', value: '5554' },
                { xi: '城乡规划', value: '5555' }
              ]
            },
            { label: '系部简介', value: '5556' },
            { label: '机构设置', value: '5557' },
            { label: '师资力量', value: '5558' },
            { label: '人才培养方案', value: '5559' },
            { label: '教学管理', value: '5560' },
            { label: '教学教研', value: '5561' }
          ]
        },
        {
          name: '思政教研室',
          img: xiaotubiao6,
          son: [
            { label: '教研室简介', value: '6662' },
            { label: '课程设置', value: '6663' },
            { label: '师资力量', value: '6664' },
            { label: '教学管理', value: '6665' },
            { label: '教研活动', value: '6666' },
            { label: '理论前沿', value: '6667' },
            { label: '学习专栏', value: '6668' }
          ]
        }
      ]
    }
  },
  mounted() {},
  methods: {
    // 手机端系部导航
    goXi(item1, item2, item3) {
      console.log(item1)
      console.log(item2)
      console.log(item3)
      this.$router.push({
        path: '/xi',
        query: { name1: item1.name, name2: item2.label, name3: item3 ? item3.xi : '', id: item3 ? item3.value : item2.value }
      })
    }
  }
}
</script>
<style scoped>
/* 系部导航 */
.bg_menu {
  width: 160px;
  height: 612px;
  margin-right: 10px;
  /* position: absolute;
  top: 22px;
  left: calc((100% - 1200px) / 2 - 190px); */
  z-index: 999;
  border: 5px solid #5084bf;
  border-radius: 5px;
}
.bg_menu .el-menu--collapse .el-submenu .el-submenu__title {
  background: red !important;
  text-align: right;
}
.bg_menu .el-menu--collapse .el-submenu .el-submenu__title span {
  visibility: initial;
  display: inline;
  /* color: rgb(14, 73, 141); */
}
.bg_menu .el-menu--collapse {
  width: 160px;
  height: 560px;
}
.bg_menu .el-menu--collapse .el-menu-item span {
  visibility: initial;
  display: inline;
  /* color: rgb(14, 73, 141); */
}
.bg-menu ::v-deep .el-menu {
  border-right: 0 !important;
  /* background: #f6fbff; */
  background: red;
}
.menu_title {
  width: 160px;
  height: 50px;
  font-size: 19px;
  background: #5084bf;
  text-align: center;
  line-height: 50px;
  /* border-right: solid 1px #5084bf; */
  color: #fff;
}
.card_body_div {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 20px 10px 20px;
  text-align: center;
  cursor: pointer;
}
.card_body_img {
  height: 45px;
  object-fit: contain;
  margin-top: 18px;
}
.card_body_p {
  font-size: 15px;
  margin-top: 20px;
}
.nav_item_right {
  font-size: 15px;
  color: #999;
}
</style>